#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header,
footer {
  flex-shrink: 0;
}

body {
  min-height: 100vh;
  background: #eee !important;
}

footer {
  margin-top: 1rem;
}

main {
  flex-grow: 1;
  margin: 2rem 0;
}

.modal-title-icon {
  position: relative;
  bottom: 3px;
}

/* Override styling from bokinfos custom boostrap theme. Should be fixed in bokinfo-ui package instead */
.pagination {
  width: unset !important;
}

.rt-table__container {
  width: fit-content;
}
.rt-table__global-filter {
  max-width: 300px;
}

/* 
Temporarily override styling from current version of bokinfos custom boostrap theme.
This styling is used in Bokinfo.se but not synchronized in bokinfo-ui package.
Should be fixed bokinfo-ui package and then have the code below removed.
*/
.form-select {
  padding-right: 2.25rem !important;
}
